import type { Attachment } from "@ai-sdk/ui-utils";
import { Modal } from "@mantine/core";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { useFetcher, useSearchParams } from "@remix-run/react";
import { assignmentService } from "modules/assignments/assignment.service";
import { AssignmentWithQuestions } from "modules/assignments/assignment.types";
import { anonymousTeacher } from "modules/auth/anonymousUser";
import { getUser } from "modules/auth/authHelper.server";
import { groupService } from "modules/groups/group.service";
import { GroupDropdownItem } from "modules/groups/group.types";
import { profileService } from "modules/profiles/service/profile.service";
import { useEffect, useState } from "react";
import { typedjson, useTypedLoaderData } from "remix-typedjson";
import AssignmentEditor from "~/components/AssignmentEditor";
import { GenericErrorBoundary } from "~/components/ErrorBoundary/GenericErrorBoundary";
import { NavRoute } from "~/components/Header/Header";
import { Cta } from "~/components/LandingPage/Cta";
import { FAQ } from "~/components/LandingPage/FAQ";
import { Footer } from "~/components/LandingPage/Footer";
import { HeroSection } from "~/components/LandingPage/HeroSection";
import { HowPodsieWorks } from "~/components/LandingPage/HowPodsieWorks";
import { PilotBanner } from "~/components/LandingPage/PilotBanner";
import { Testimonials } from "~/components/LandingPage/Testimonials";
import AppLayout from "~/layouts/_app";
import { getAssignmentDataFromRequestAndCookies } from "~/utils/assignment.cookie";

export { GenericErrorBoundary as ErrorBoundary };

export const meta: MetaFunction = () => {
  return [
    { title: "Podsie" },
    {
      name: "description",
      content: "Podsie helps your students remember what they learn!",
    },
  ];
};
export const teacherBaseNavRoutes: NavRoute[] = [
  {
    label: "Content Library",
    href: "/content-library",
  },
];

// Define the return type of the loader
type LoaderData =
  | { assignment: null; groups: GroupDropdownItem[] }
  | {
      assignment: AssignmentWithQuestions;
      chatId?: string;
      groups: GroupDropdownItem[];
    };

// public route
export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { user } = await getUser(request);
  const { assignmentId, chatId, headers } =
    await getAssignmentDataFromRequestAndCookies(request);
  let groups: GroupDropdownItem[] = [];
  if (user) {
    groups = await groupService.getTeacherGroups(user.id);
  }

  if (assignmentId) {
    try {
      const assignment = await assignmentService.getAssignmentWithQuestions(
        parseInt(assignmentId),
      );
      if (
        assignment.createdBy !== anonymousTeacher.id &&
        user?.id !== assignment.createdBy
      ) {
        return typedjson({ assignment: null }, { headers });
      }
      return typedjson({ assignment, chatId, groups }, { headers });
    } catch (error) {
      console.error("Error fetching assignment", error);
      // If assignment not found or other error, return null
      return typedjson({ assignment: null, groups }, { headers });
    }
  }

  return typedjson({ assignment: null, groups }, { headers });
};

// public route
export const action = async ({ request }: ActionFunctionArgs) => {
  const { user } = await getUser(request);
  let teacherProfile;
  if (user) {
    teacherProfile = await profileService.findTeacherProfileByUserId(user.id);
  }
  const assignment = await assignmentService.createAssignment(
    {
      title: "Sample Assignment",
      groupsAssigned: [],
    },
    teacherProfile?.id || anonymousTeacher.id,
  );
  return { assignment };
};

export default function Home() {
  const [_searchParams, setSearchParams] = useSearchParams();
  const data = useTypedLoaderData<typeof loader>() as LoaderData;
  const loadedAssignment = data.assignment || null;
  const groups = data.groups || [];
  // State to track if the full editor is open
  const [editorOpen, setEditorOpen] = useState(false);
  // Store the initial input to pass to the editor
  const [initialInput, setInitialInput] = useState("");
  // Store the initial attachments to pass to the editor
  const [initialAttachments, setInitialAttachments] = useState<Attachment[]>(
    [],
  );
  // Store the actual assignment returned from the action or loader
  const [currentAssignment, setCurrentAssignment] =
    useState<AssignmentWithQuestions | null>(loadedAssignment);

  // Use fetcher to call our action
  const fetcher = useFetcher<typeof action>();

  // Set editor open if we have a loaded assignment
  useEffect(() => {
    if (loadedAssignment) {
      setCurrentAssignment(loadedAssignment);
      setEditorOpen(true);
    }
  }, [loadedAssignment]);

  // Handle the user starting to type in the simplified form
  const handleStartTyping = async (
    input: string,
    attachments?: Attachment[],
  ) => {
    // Store the input and attachments
    setInitialInput(input);
    if (attachments && attachments.length > 0) {
      setInitialAttachments(attachments);
    }

    // Call the action to create a new assignment
    fetcher.submit({}, { method: "post" });
  };

  // Function to resume the conversation by reopening the modal
  const resumeConversation = () => {
    setEditorOpen(true);
  };

  // Effect to handle when fetcher returns data
  useEffect(() => {
    if (fetcher.data && fetcher.data.assignment) {
      // Store the assignment data
      const newAssignment = {
        ...fetcher.data.assignment,
        assignmentQuestions: [],
        groupsAssigned: [],
      };

      setCurrentAssignment(newAssignment);

      // Add assignment ID to URL query parameter
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.set("assignmentId", newAssignment.id.toString());
        return newParams;
      });

      // Now that we have the assignment, open the editor
      setEditorOpen(true);
    }
  }, [fetcher.data, setSearchParams]);

  // Close the full editor and remove the assignment from URL
  const handleCloseEditor = () => {
    setEditorOpen(false);

    // Clear cookies by making a fetch request
    fetch("/api/assignment-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        assignmentId: "",
        chatId: "",
        redirectTo: window.location.pathname,
      }),
    }).catch((error) => {
      console.error("Error clearing cookies:", error);
    });
  };

  return (
    <AppLayout>
      <PilotBanner />
      <HeroSection
        handleStartTyping={handleStartTyping}
        hasExistingAssignment={currentAssignment !== null}
        resumeConversation={resumeConversation}
      />
      <HowPodsieWorks />
      <Testimonials />
      <FAQ />
      <Cta />
      <Footer />

      <Modal
        opened={editorOpen}
        onClose={handleCloseEditor}
        fullScreen
        keepMounted
        withCloseButton={false}
        padding={0}
        styles={{
          content: {
            height: "100vh",
            maxHeight: "100vh",
          },
        }}
      >
        <AssignmentEditor
          groups={groups}
          assignment={currentAssignment}
          onClose={handleCloseEditor}
          initialInput={initialInput}
          initialAttachments={initialAttachments}
        />
      </Modal>
    </AppLayout>
  );
}
